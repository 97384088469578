@layer modules, ui, base;
@layer base {
  .AI_badge__eONwN {
  background: linear-gradient(90.31deg, #643cba 0.53%, #ff0099 99.47%);
  color: var(--sk-color-white);
}

.AI_button__F_G82,
.AI_heading__aZk8P {
  background: linear-gradient(90.31deg, #643cba 0.53%, #ff0099 99.47%);
  color: var(--sk-color-white);
}

.AI_heading__aZk8P {
  padding: var(--sk-space-2) var(--sk-space-4);
  border-radius: var(--sk-radius-4);
}

.AI_button__F_G82:hover,
.AI_button__F_G82:active,
.AI_button__F_G82:focus-visible {
  background: linear-gradient(90.31deg, #513095 0.53%, #cb017a 99.47%);
}
}
@layer base {
  .Contacts_contactsWrapper__fTxE2 {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--sk-space-48);
  text-align: center;
}

.Contacts_contactsTitle__0zAEc {
  margin-bottom: var(--sk-space-16);
  word-break: break-word;
}

.Contacts_contactsContainer__WZg0_ {
  display: grid;
  grid-gap: var(--sk-space-24) var(--sk-space-16);
  grid-template-columns: 1fr;
  padding: 0;
  list-style-type: none;
}

.Contacts_contactsItem__2uhvT {
  box-sizing: border-box;
  padding: var(--sk-space-32) var(--sk-space-24);
  border-left: 1px solid var(--sk-color-grey-100);
  background: var(--sk-color-white);
  text-align: start;
  word-break: break-word;
}

.Contacts_icon___4PMd {
  flex-shrink: 0;
  margin-right: var(--sk-space-8);
}

.Contacts_contactHeader__FbSXi {
  margin-bottom: var(--sk-space-24);
}

.Contacts_contactDetails__4mcVr {
  display: flex;
  margin-bottom: var(--sk-space-12);
}

.Contacts_contactsLinks__EVrA6 {
  all: unset;
  cursor: pointer;
}

.Contacts_email__UAVWj {
  display: flex;
}

.Contacts_showMoreLess___3rBR {
  margin-top: var(--sk-space-16);
}

@media (max-width: 767px) {
  .Contacts_contactsHidden__PTcbA div:nth-child(n + 4) {
    display: none;
  }
}

@media (min-width: 768px) {
  .Contacts_showMoreLess___3rBR {
    display: none;
  }

  .Contacts_contactsContainer__WZg0_ {
    grid-template-columns: 1fr 1fr;
  }

  .Contacts_contactsContainerOneContact__JI6_Z {
    display: flex;
    align-self: center;
    width: 500px;
  }

  .Contacts_contactsTitle__0zAEc {
    margin-bottom: var(--sk-space-32);
  }

  .Contacts_contactsItem__2uhvT {
    flex: 1 0 40%;
  }

  .Contacts_contactDetails__4mcVr {
    margin-bottom: var(--sk-space-16);
  }
}

@media (min-width: 1312px) {
  .Contacts_contactsWrapper__fTxE2 {
    margin-bottom: var(--sk-space-80);
  }

  .Contacts_contactsTitle__0zAEc {
    margin-bottom: var(--sk-space-40);
  }

  .Contacts_contactsItem__2uhvT {
    flex: 1 0 40%;
  }
}

}
@layer base {
  .ResumeAiFeedback_aiNotification__zZMkm {
  margin-bottom: var(--sk-space-16);
}

}
@layer base {
  .AsideCsSpotlight_card__IQnLy {
  --sk-data-display-card-color-border-default-light: var(--sk-color-grey-100);
  --sk-data-display-card-body-content-gap: var(--sk-space-12);
  position: relative;
}

.AsideCsSpotlight_headerImage__8LDgV {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.AsideCsSpotlight_logo__U7ZSK {
  margin-left: var(--sk-space-8);
  padding: var(--sk-space-4);
  border-radius: var(--sk-radius-4);
  background: var(--sk-color-white);
}

.AsideCsSpotlight_logo__U7ZSK:first-child {
  margin-left: calc(var(--sk-space-4) * -1);
}

.AsideCsSpotlight_logoRow___TtWg {
  z-index: var(--z-up-in-the-current-stacking-context); /* Above the card cover */
  margin-top: -40px;
  margin-bottom: calc(var(--sk-space-12) * -1);
}

.AsideCsSpotlight_link__Tc2VW {
  color: var(--sk-color-black);
  text-decoration: none;
}

.AsideCsSpotlight_link__Tc2VW:focus-visible {
  outline-color: var(--sk-navigation-link-color-border-focus-light);
}

.AsideCsSpotlight_link__Tc2VW::before {
  content: "";
  position: absolute;
  /* above the card and logoRow */
  z-index: calc(var(--z-up-in-the-current-stacking-context) + 1);
  inset: 0;
  cursor: pointer;
}

}
@layer base {
  .CsSpotlightLink_main__jGniI {
  /* above the card to make it clickable with the global link of the card */
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  z-index: 3;
}

}
@layer base {
  .Loading_loading__dFYAx {
  animation: Loading_pulse___pk8X 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  border-radius: var(--sk-radius-2);
  background-color: var(--sk-color-grey-050);
}

.Loading_loading__withBorderRadius__07bQy {
  border-radius: var(--sk-radius-16);
}

@keyframes Loading_pulse___pk8X {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

}
@layer base {
  .LoadingContentAside_loadingContentAside__e_MVG {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-16);
}

}
@layer base {
  .AsidePartnersCompany_container__akxR3 {
  display: grid;
  gap: var(--sk-space-8);
  grid-template-columns: repeat(5, minmax(0, 1fr));
  margin: var(--sk-space-16) 0 var(--sk-space-8) 0;
  padding: 0;
  list-style-type: none;
}

.AsidePartnersCompany_link__mskzo {
  display: flex;
}

.AsidePartnersCompany_link__mskzo:focus-visible {
  border-radius: var(--sk-radius-4);
  outline: none;
  box-shadow:
    var(--sk-ressource-focus-ring-shadow-light-shadow-3),
    var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
}

.AsidePartnersCompany_partnerCompany__ybIoZ {
  box-sizing: border-box;
  width: 100%;
  max-width: 110px;
  height: 100%;
  border: 1px solid var(--sk-color-grey-100);
  border-radius: var(--sk-space-4);
}

}
@layer base {
  .UsefulLinksCard_main__H_xfn {
  --sk-data-display-card-color-border-default-light: var(--sk-color-grey-100);
}

.UsefulLinksCard_cardBody__NPkxm {
  margin: var(--sk-space-16);
}

.UsefulLinksCard_richText__w89jB {
  font-size: var(--sk-typography-body-1-regular-font-size);
  line-height: var(--sk-typography-body-1-regular-line-height);
}

.UsefulLinksCard_richText__w89jB * {
  color: var(--sk-color-grey-900);
}

.UsefulLinksCard_richText__w89jB p {
  margin: 0;
}

.UsefulLinksCard_richText__w89jB p:empty {
  display: none;
}

.UsefulLinksCard_richText__w89jB img,
.UsefulLinksCard_richText__w89jB iframe {
  width: 100%;
  height: auto;
}

.UsefulLinksCard_richText__w89jB a:focus-visible {
  outline-color: var(--sk-navigation-link-color-border-focus-light);
}

}
@layer base {
  .AsideUsefulLinks_title__FHQuJ {
  margin-bottom: var(--sk-space-16);
}

.AsideUsefulLinks_cardsContainer__dOXxe {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-12);
}

}
@layer base {
  .Aside_aside__G_fnN {
  display: flex;
  gap: var(--sk-space-40);
  grid-column: 1 / 5;
  flex-direction: column;
}

.Aside_divider__xGT_0 {
  margin-top: var(--sk-space-32);
}

@media (min-width: 768px) {
  .Aside_aside__G_fnN {
    grid-column: 9 / 13;
  }

  .Aside_divider__xGT_0 {
    display: none;
  }
}

@media (min-width: 80rem) {
  .Aside_aside__G_fnN {
    grid-column: 10 / 13;
  }
}

}
@layer base {
  .CardContentBoost_card__dYRFb {
  --sk-data-display-card-color-border-default-light: var(--sk-color-grey-100);
}

.CardContentBoost_card__dYRFb:hover {
  --sk-data-display-card-color-border-default-light: var(--sk-color-grey-200);
}

.CardContentBoost_cardFooter__p_XAU {
  display: flex;
  align-items: center;
  gap: var(--sk-space-16);
}

/* Horizontal card in responsive */

@container --sk-card (min-width: 448px) {
  .CardContentBoost_cardCover__YF_gL {
    max-width: 33%;
  }
}

}
@layer base {
  .ContentBoosts_contentBoosts__x2MVW {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-16);
}

.ContentBoosts_wrapper__3b4xQ {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-16);
}

.ContentBoosts_link__u7BI8 {
  display: block;
  text-decoration: none;
}

.ContentBoosts_link__u7BI8:focus-visible {
  border-radius: var(--sk-data-display-card-radius);
  outline: none;
  background: transparent;
  box-shadow:
    var(--sk-ressource-focus-ring-shadow-light-shadow-3),
    var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
  --sk-data-display-card-color-border-default-light: var(--sk-color-grey-200);
}

}
@layer base {
  .BodyContent_partner__QmfT4 {
  display: flex;
  align-items: center;
  gap: var(--sk-space-8);
}

.BodyContent_iconTextContainer__iwt9Y {
  display: flex;
  align-items: flex-start;
  gap: var(--sk-space-8);
}

.BodyContent_icon__5V7cK {
  flex-shrink: 0;
  margin-top: var(--sk-space-2);
}

.BodyContent_iconRegistrationStatus__NKJcS {
  color: var(--sk-color-success-500);
}

.BodyContent_text__YXwos {
  /* see https://css-tricks.com/almanac/properties/l/line-clamp/ */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

}
@layer base {
  .Dates_main___TyKD {
  margin-bottom: var(--sk-space-8);
}

.Dates_mainBig__mC00m {
  display: none;
}

@media (min-width: 768px) {
  .Dates_hiddenOnLargeScreen__on9o_.Dates_main___TyKD {
    display: none;
  }

  .Dates_displayedOnLargeScreen__BA_5s.Dates_mainBig__mC00m {
    display: flex;
    flex: 0 0 var(--sk-size-72);
    flex-direction: column;
    margin-right: var(--sk-space-16);
  }

  .Dates_displayedOnLargeScreen__BA_5s .Dates_month__fp74V {
    text-transform: uppercase;
  }

  .Dates_displayedOnLargeScreen__BA_5s .Dates_day__BBnV4 {
    font-family: var(--sk-typography-display-2-font-family);
    font-size: var(--sk-typography-display-2-font-size);
    font-weight: var(--sk-typography-display-2-font-weight);
    line-height: var(--sk-typography-display-2-line-height);
  }

  .Dates_displayedOnLargeScreen__BA_5s .Dates_month__fp74V + .Dates_day__BBnV4 {
    margin-top: var(--sk-space-8);
  }
}

}
@layer base {
  .SchoolBadge_main__ZZ30J {
  display: flex;
  align-items: center;
  gap: var(--sk-space-12);
}

}
